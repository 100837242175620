import { gql } from "@apollo/client";

export const GET_ALL_PAYMENTS = (date: string) => {
  return gql`
    query getPayments {
      irctc_payment_status(
        order_by: {txn_date: desc}
        where: {
          result_status: { _eq: "TXN_SUCCESS" }
          txn_date: {
            _ilike: "%${date}%"
          }
        }
      ) {
        gateway_stage
        txn_date
        order_id
        error {
          url
          data
        }
      }
    }
  `;
};
