/* eslint-disable react-hooks/exhaustive-deps */
import dayjs from "dayjs";
import { useGetFootfall } from "../../hooks/footfall.hook";
import { useEffect } from "react";
import moment from "moment";
import Plotly from "plotly.js-dist-min";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { ProgressBar } from "primereact/progressbar";
import { Button } from "primereact/button";

const FootFall = () => {
  const { data, loading, date, minDate, setDate, setReload } = useGetFootfall();

  useEffect(() => {
    setDate(moment(Date.now()).add(-2, "days").format("YYYY-MM-DD 00:00:00.0"));
  }, []);

  useEffect(() => {
    if (data.length > 0) {
      Plotly.newPlot("footfall-funnel", data, {
        margin: { l: 200 },
        // hovermode: false,
        title: "Ticket Booking Funnel",
        barmode: "stack",
        // showlegend: true,
      });
    }
  }, [data]);

  return (
    <>
      <div style={{ display: "flex", alignItems: "center" }}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            label="Select Date"
            format="DD/MM/YYYY"
            value={dayjs(date)}
            minDate={minDate}
            maxDate={dayjs(Date.now()).add(-2, "days")}
            onChange={(e: any) =>
              setDate(moment(e["$d"]).format("YYYY-MM-DD 00:00:00.0"))
            }
            sx={{ marginRight: 2, marginTop: 2, marginBottom: 2 }}
          />
        </LocalizationProvider>
        <Button
          icon="pi pi-refresh"
          loading={loading}
          className="mr-2"
          onClick={() => setReload(true)}
        />
      </div>
      {loading && (
        <ProgressBar mode="indeterminate" style={{ height: "4px" }} />
      )}
      <div id="footfall-funnel"></div>
    </>
  );
};

export default FootFall;
