import { loginInputWrapper } from './styles';
import 'primeicons/primeicons.css';
import { Card } from 'primereact/card';
import { Password } from 'primereact/password';
import { Button } from 'primereact/button';
import { useState } from 'react';

const Login = ({ handleLogin }: any) => {
  const [key, setKey] = useState('');

  const onKey = (e: any) => {
    if (e.code === 'Enter' && key !== '') {
      handleLogin(key);
    }
  };

  return (
    <div style={{ ...loginInputWrapper }}>
      <Card
        title="Dashboard Login"
        footer={<Button label="Login" onClick={() => handleLogin(key)} />}
      >
        <div className="p-inputgroup flex-2">
          <span className="p-inputgroup-addon">
            <i className="pi pi-key"></i>
          </span>
          <Password
            placeholder="Key"
            value={key}
            onChange={e => setKey(e.target.value)}
            feedback={false}
            onKeyDown={onKey}
          />
        </div>
      </Card>
    </div>
  );
};

export default Login;
