export const OverviewFilters = {
  marginTop: 10,
  marginBottom: 10,
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
} as React.CSSProperties;

export const CenterPickers = {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
} as React.CSSProperties;
