/* eslint-disable react-hooks/exhaustive-deps */
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import moment from "moment";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { ProgressBar } from "primereact/progressbar";
import { useEffect } from "react";
import { CenterPickers } from "./styles";

interface SinglePickerProps {
  columns: Array<any>;
  isHourly: boolean;
  data: Array<any>;
  date: string;
  setDate: Function;
  loading: boolean;
  setReload: Function;
  min?: dayjs.Dayjs;
  max?: dayjs.Dayjs;
}

const SinglePickerTable = ({
  columns,
  isHourly,
  data,
  date,
  setDate,
  loading,
  setReload,
  min,
  max,
}: SinglePickerProps) => {
  useEffect(() => {
    setDate(
      max
        ? moment(max.valueOf()).format("YYYY-MM-DD 00:00:00.0")
        : moment(Date.now()).format("YYYY-MM-DD 00:00:00.0")
    );
  }, []);

  return (
    <>
      <div style={CenterPickers}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            label="Select Date"
            format="DD/MM/YYYY"
            value={dayjs(date)}
            minDate={min ?? dayjs().add(-10, "years")}
            maxDate={max ?? dayjs()}
            onChange={(e: any) =>
              setDate(moment(e["$d"]).format("YYYY-MM-DD 00:00:00.0"))
            }
            sx={{ marginRight: 2, marginTop: 2, marginBottom: 2 }}
          />
        </LocalizationProvider>
        <Button
          icon="pi pi-refresh"
          loading={loading}
          className="mr-2"
          onClick={() => setReload(true)}
        />
      </div>
      {loading && (
        <ProgressBar mode="indeterminate" style={{ height: "4px" }} />
      )}
      <DataTable
        value={data}
        tableStyle={{ minWidth: "30rem" }}
        showGridlines
        stripedRows
        style={{ marginTop: "8px", marginBottom: "8px" }}
      >
        {columns.map((elem, index) => (
          <Column
            key={index}
            field={elem.value}
            header={isHourly && index === 0 ? "Hourly Interval" : elem.name}
          />
        ))}
      </DataTable>
    </>
  );
};

export default SinglePickerTable;
