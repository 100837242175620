import { useQuery } from "@apollo/client";
import { ParameterType } from "../../types/parameters.types";
import { useEffect } from "react";
import { triggerDownload } from "../../utils/misc.utils";
import { CSV_QUERY, DataKey, TableName } from "../../utils/table.utils";

const CSVTool = (props: {
  tableName: TableName;
  parameters: ParameterType;
  toggle: Function;
}) => {
  const { data } = useQuery(CSV_QUERY[props.tableName](props.parameters));

  useEffect(() => {
    if (data) {
      props.toggle(false);
      triggerDownload(data[DataKey[props.tableName]]);
    }
  }, [data]); // eslint-disable-line
  return <></>;
};

export default CSVTool;
