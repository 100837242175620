import { gql } from "@apollo/client";
import { ParameterType } from "../types/parameters.types";
import { getFormattedTime } from "../utils/misc.utils";

export const DEFAULT_PAYMENT_COLUMNS = [
  "order_id",
  "bank_txn_id",
  "card_scheme",
  "payment_mode",
  "result_status",
  "txn_date",
  "txn_amount",
  "txn_id",
  "mobile",
  "userid",
];

const aggregateQuery = (parameters: ParameterType) => {
  return `irctc_payment_status_aggregate(where: {
    txn_date: {
      _gte: "${getFormattedTime(parameters.filter.interval.start)}",
      _lte: "${getFormattedTime(parameters.filter.interval.end)}"
    }}) {
    aggregate {
      count
    }
  }`;
};

export const GET_PAYMENTS = (parameters: ParameterType) => {
  const searchQuery = parameters.filter.searchValues
    ? `${parameters.filter.searchValues.column}: {
    _eq: "${parameters.filter.searchValues.value}"
  }`
    : "";

  return gql`
    query getPayments {
      ${aggregateQuery(parameters)}
      irctc_payment_status(
        ${searchQuery === "" ? `limit: ${parameters.limit ?? 10}` : ""}
        offset: ${parameters.offset ?? 0}, order_by: {txn_date: desc}, where: {
    ${searchQuery}
    ${
      searchQuery === ""
        ? `txn_date: {
      _gte: "${getFormattedTime(parameters.filter.interval.start)}",
      _lte: "${getFormattedTime(parameters.filter.interval.end)}"
    }`
        : ""
    }
    }) {
        ${parameters.columns.join("\n")}
      }
    }
  `;
};

export const GET_PAYMENTS_CSV = (parameters: ParameterType) => {
  const searchQuery = parameters.filter.searchValues
    ? `${parameters.filter.searchValues.column}: {
    _eq: "${parameters.filter.searchValues.value}"
  }`
    : "";

  return gql`
  query getPayments {
    irctc_payment_status(offset: ${
      parameters.offset ?? 0
    }, order_by: {txn_date: desc}, where: {
  ${searchQuery}
  ${
    searchQuery === ""
      ? `txn_date: {
    _gte: "${getFormattedTime(parameters.filter.interval.start)}",
    _lte: "${getFormattedTime(parameters.filter.interval.end)}"
  }`
      : ""
  }
  }) {
      ${parameters.columns.join("\n")}
    }
  }
`;
};
