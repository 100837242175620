/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { useGetTableData } from "../../hooks/tableData.hook";
import { ParameterType } from "../../types/parameters.types";
import { TableName } from "../../utils/table.utils";
import Table from "./table";
import { ProgressBar } from "primereact/progressbar";

const TableWrapper = (props: {
  tableName: TableName;
  columns: any[];
  parameters: ParameterType;
  reload: boolean;
  setReload: Function;
  onPageChange: (page: number) => void;
  onRowPerPageChange: (currentRowsPerPage: number) => void;
}) => {
  const { tableData, loading, getData } = useGetTableData(
    props.tableName,
    props.parameters,
    props.setReload
  );

  useEffect(() => {
    if (props.reload) {
      getData(true);
    }
  }, [props.reload]);

  return (
    <>
      {loading && (
        <ProgressBar mode="indeterminate" style={{ height: "4px" }} />
      )}
      <Table
        columns={props.columns}
        rows={tableData.data}
        totalRows={tableData.count}
        onPageChange={props.onPageChange}
        onRowPerPageChange={props.onRowPerPageChange}
      ></Table>
    </>
  );
};

export default TableWrapper;
