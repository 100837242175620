/* eslint-disable react-hooks/exhaustive-deps */
import { useGetHourlyData } from "../../hooks/hourly.hook";
import SinglePickerTable from "../common/singlePickerTable";
import { IntervalColumns } from "../../utils/table.utils";

const HourlyData = () => {
  const { loading, date, data, setDate, setReload } = useGetHourlyData();

  return (
    <SinglePickerTable
      loading={loading}
      date={date}
      data={data}
      setDate={setDate}
      isHourly={true}
      columns={IntervalColumns}
      setReload={setReload}
    />
  );
};

export default HourlyData;
