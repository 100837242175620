import { useMutation } from '@apollo/client';
import { useState } from 'react';
import { LOGIN_MUTATION } from '../graphql/login.mutation';

const useLogin = () => {
  const [isLoggedIn] = useState(!!localStorage.getItem('token') ?? false);
  const [onLogin] = useMutation(LOGIN_MUTATION);

  const setToken = (token: string) => {
    localStorage.setItem('token', token);
    window.location.reload();
  };

  return { isLoggedIn, onLogin, setToken };
};

export default useLogin;
