/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { errorTypes } from "../utils/misc.utils";
import moment from "moment";
import { apoloClient } from "../utils/apollo.utils";
import { GET_ERROR_COUNT, GET_ERROR_PAYMENTS } from "../graphql/error.query";

interface Row {
  errorType: string;
  count: number;
}

const useGetErrorsHook = () => {
  const [loading, setLoading] = useState(false);
  const [date, setDate] = useState("");
  const [data, setData] = useState<Array<Row>>([]);
  const [reload, setReload] = useState(false);

  const getData = async (trigger: any) => {
    setLoading(true);
    const formattedDate = moment(date).format("YYYY-MM-DD");

    const payments = await apoloClient.query({
      query: GET_ERROR_PAYMENTS(formattedDate),
    });

    const orderIds = payments.data.irctc_payment_status.map(
      (elem: { order_id: any }) => elem.order_id
    );

    let total = 0;

    for (const error of errorTypes) {
      const countData = await apoloClient.query({
        query: GET_ERROR_COUNT(error.query, orderIds),
        fetchPolicy: reload ? "network-only" : "cache-first",
      });

      if (trigger.clear) {
        return;
      }

      total += countData.data.irctc_new_error_aggregate.aggregate.count;

      setData((prev) => [
        ...prev,
        {
          errorType: error.name,
          count: countData.data.irctc_new_error_aggregate.aggregate.count,
        },
      ]);
    }

    setData((prev) => [
      ...prev,
      {
        errorType: "Not identified",
        count: orderIds.length - total,
      },
      {
        errorType: "Total",
        count: orderIds.length,
      },
    ]);

    setLoading(false);
    setReload(false);
  };

  useEffect(() => {
    const trigger = { clear: false };
    if (date !== "") {
      setData(() => []);
      getData(trigger);
    }

    return () => {
      trigger.clear = true;
    };
  }, [date]);

  useEffect(() => {
    const trigger = { clear: false };
    if (reload) {
      setData(() => []);
      getData(trigger);
    }
    return () => {
      trigger.clear = true;
    };
  }, [reload]);

  return {
    loading,
    date,
    data,
    setDate,
    setReload,
  };
};

export { useGetErrorsHook };
