/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { GET_INTERVAL_BOOKINGS } from "../graphql/booking.query";
import { apoloClient } from "../utils/apollo.utils";
import moment from "moment";
import { bifurcateHourlyData } from "../utils/table.utils";

interface Row {
  time: string;
  count: number;
}
const useGetHourlyData = () => {
  const [loading, setLoading] = useState(false);
  const [date, setDate] = useState("");
  const [data, setData] = useState<Array<Row>>([]);
  const [reload, setReload] = useState(false);

  const getDates = () => {
    const startDate = moment(date, "YYYY-MM-DD HH:mm:ss.0").format(
      "MM/DD/YYYY 00:00:00"
    );
    const endDate = moment(date, "YYYY-MM-DD HH:mm:ss.0").format(
      "MM/DD/YYYY 29:59:00"
    );

    return { startDate, endDate };
  };

  const getData = async () => {
    const { startDate, endDate } = getDates();
    const bookings = await apoloClient.query({
      query: GET_INTERVAL_BOOKINGS(startDate, endDate),
      fetchPolicy: reload ? "network-only" : "cache-first",
    });

    const bifurcated = bifurcateHourlyData(bookings.data.irctc_Booking, date);

    setData(bifurcated);
    setLoading(false);
    setReload(false);
  };

  useEffect(() => {
    if (date !== "") {
      setLoading(true);
      setData(() => []);
      getData();
    }
  }, [date]);

  useEffect(() => {
    if (reload) {
      setData(() => []);
      getData();
    }
  }, [reload]);

  return {
    loading,
    date,
    data,
    setDate,
    setReload,
  };
};

export { useGetHourlyData };
