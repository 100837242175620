import { gql } from "@apollo/client";

export const GET_FOOTFALL = (date: string) => {
  return gql`
    query getFootfall {
      irctc_footfall(
        where: { date: { _eq: "${date}"} }
      ) {
        data
      }
    }
  `;
};

export const GET_FOOTFALL_DATE = () => {
  return gql`
    query getFootfall {
      irctc_footfall(limit: 1, order_by: { date: asc }) {
        date
      }
    }
  `;
};

export const GET_FOOTFALL_PAYMENTS = (date: string) => {
  return gql`
    query getPayments {
      irctc_payment_status_aggregate(
        where: {
          result_status: { _eq: "TXN_SUCCESS" }
          txn_date: { _like: "%${date}%" }
        }
      ) {
        aggregate {
          count
        }
      }
    }
  `;
};

export const GET_FOOTFALL_BOOKINGS = (date: string) => {
  return gql`
    query getPayments {
      irctc_payment_status_aggregate(
        where: {
          result_status: { _eq: "TXN_SUCCESS" }
          gateway_stage: {_eq: "2"}
          txn_date: { _like: "%${date}%" }
        }
      ) {
        aggregate {
          count
        }
      }
    }
  `;
};
