import { useRef } from "react";
import Login from "./components/login";
import useLogin from "./hooks/login.hook";
import { Toast } from "primereact/toast";
import "./index.css";
import { Menubar } from "primereact/menubar";
import RawData from "./components/rawData";
import Overview from "./components/overview";
import TQData from "./components/tqData";
import { Route, Routes, useNavigate } from "react-router-dom";
import HourlyData from "./components/hourlyData";
import ErrorData from "./components/errorData";
import FootFall from "./components/footfall";

function App() {
  const navigate = useNavigate();
  const { isLoggedIn, onLogin, setToken } = useLogin();
  const toast: any = useRef(null);
  const items = [
    {
      label: "Overview",
      icon: "pi pi-home",
      command: () => {
        navigate("/");
      },
    },
    {
      label: "ARP and TQ Report",
      icon: "pi pi-bolt",
      command: () => {
        navigate("/tatkal-overview");
      },
    },
    {
      label: "Hourly bookings",
      icon: "pi pi-clock",
      command: () => {
        navigate("/hourly-bookings");
      },
    },
    {
      label: "Error Report",
      icon: "pi pi-exclamation-circle",
      command: () => {
        navigate("/error-reports");
      },
    },
    // {
    //   label: "Footfall",
    //   icon: "pi pi-forward",
    //   command: () => {
    //     navigate("/footfall");
    //   },
    // },
    {
      label: "Raw Data",
      icon: "pi pi-database",
      command: () => {
        navigate("/raw-records");
      },
    },
    // {
    //   label: "Refund Processor",
    //   icon: "pi pi-refresh",
    //   command: () => {
    //     setCurrentTab(1);
    //   },
    // },
    // {
    //   label: "Manual Refund",
    //   icon: "pi pi-refresh",
    //   command: () => {
    //     setCurrentTab(2);
    //   },
    // },
    {
      label: "Log out",
      icon: "pi pi-sign-out",
      command: () => {
        if (window.confirm("Are you sure?")) {
          localStorage.clear();
          window.location.reload();
        }
      },
    },
  ];

  const showToast = (message: string, severity: string) => {
    toast.current.show({
      severity,
      summary: "Info",
      detail: message,
    });
  };

  const handleLogin = (key: string) => {
    onLogin({ variables: { loginInput: { key } } })
      .then((response) => {
        setToken(response.data.login.accessToken);
      })
      .catch((err) => {
        if (err.message === "Unauthorized") {
          showToast("Invalid key", "error");
        } else {
          showToast("Failed to login, please contact the admin", "error");
        }
      });
  };

  return (
    <>
      <div className="card">
        {isLoggedIn ? (
          <>
            <Menubar
              autoFocus={true}
              model={items}
              end={
                <div className="logo">
                  <label>Powered By</label>
                  <img src="./corover-logo-small.png" alt="logo" width="98px" />
                </div>
              }
            />
            <Routes>
              <Route path="/" index element={<Overview />} />
              <Route path="/tatkal-overview" element={<TQData />} />
              <Route path="/hourly-bookings" element={<HourlyData />} />
              <Route path="/error-reports" element={<ErrorData />} />
              <Route path="/footfall" element={<FootFall />} />
              <Route path="/raw-records" element={<RawData />} />
            </Routes>
          </>
        ) : (
          <Login handleLogin={handleLogin} />
        )}
      </div>
      <Toast ref={toast} />
    </>
  );
}

export default App;
