import { useGetOverview } from "../../hooks/overview.hook";
import { OverviewColumns } from "../../utils/table.utils";
import MultiPickerTable from "../common/multiPickerTable";

const Overview = () => {
  const {
    data,
    loading,
    startTime,
    endTime,
    setStartTime,
    setEndTime,
    setReload,
  } = useGetOverview();

  return (
    <MultiPickerTable
      data={data}
      loading={loading}
      startTime={startTime}
      endTime={endTime}
      setStartTime={setStartTime}
      setEndTime={setEndTime}
      columns={OverviewColumns}
      setReload={setReload}
    />
  );
};

export default Overview;
