/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { MultiSelect } from "primereact/multiselect";
import {
  TableName,
  getColumnList,
  getDefaultColumns,
  getSearchColumns,
} from "../../utils/table.utils";
import { getInterval } from "../../utils/misc.utils";
import moment from "moment";
import { Toolbar } from "primereact/toolbar";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";

const tableNames = Object.values(TableName);
const intervals = [
  { name: "Last 60 min", value: "hour" },
  { name: "Today", value: "today" },
  { name: "Last 24 hours", value: "day" },
  { name: "Last 7 days", value: "week" },
  { name: "Last 14 days", value: "fortnight" },
  { name: "Last 30 days", value: "month" },
  { name: "Custom", value: "custom" },
];

const Options = (props: {
  onParameterChange: Function;
  triggerCSV: Function;
  progress: boolean;
  setReload: Function;
}) => {
  const [selectedTable, setSelectedTable] = useState(tableNames[0]);
  const [selectedInterval, setSelectedInterval] = useState(intervals[0].name);
  const [showRange, setShowRange] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [selectedDates, setSelectedDates] = useState<any>({
    start: null,
    end: null,
  });
  const [selectedColumns, setSelectedColumns] = useState<string[]>([]);
  const [columnList, setColumnList] = useState(getColumnList(selectedTable));
  const [searchableColumns, setSearchableColumns] = useState<any[]>(
    getSearchColumns(selectedTable) as any[]
  );
  const [searchColumn, setSearchColumn] = useState(searchableColumns[0].value);

  useEffect(() => {
    const selectedCols = getDefaultColumns(selectedTable) as any[];

    setSelectedColumns(selectedCols);
    setColumnList(getColumnList(selectedTable));

    setSearchableColumns(getSearchColumns(selectedTable) as any[]);

    setSelectedInterval(intervals[0].name);

    const parameters = {
      columns: selectedCols,
      tableName: selectedTable,
      interval: getInterval("hour"),
    };

    props.onParameterChange(parameters);
  }, [selectedTable]);

  useEffect(() => {
    const defaultCols = getDefaultColumns(selectedTable) as any[];
    const interval =
      showRange && selectedDates.start && selectedDates.end
        ? {
            start: moment(selectedDates.start).valueOf(),
            end: moment(selectedDates.end).valueOf(),
          }
        : getInterval(
            intervals.find((elem) => elem.name === selectedInterval)!!.value
          );

    const parameters = {
      columns: [...defaultCols, ...selectedColumns],
      tableName: selectedTable,
      interval: interval,
    };

    props.onParameterChange(parameters);
  }, [selectedColumns]);

  useEffect(() => {
    if (!selectedInterval) return;

    if (selectedInterval === "Custom") {
      setShowRange(true);
    } else {
      setShowRange(false);
      props.onParameterChange({
        columns: selectedColumns,
        tableName: selectedTable,
        interval: getInterval(
          intervals.find((elem) => elem.name === selectedInterval)!!.value
        ),
      });
    }
  }, [selectedInterval]);

  useEffect(() => {
    if (selectedDates.start && selectedDates.end) {
      props.onParameterChange({
        columns: selectedColumns,
        tableName: selectedTable,
        interval: {
          start: moment(selectedDates.start).add(5.5, "hours").valueOf(),
          end: moment(selectedDates.end).add(5.5, "hours").valueOf(),
        },
      });
    }
  }, [selectedDates]);

  const onSearch = () => {
    props.onParameterChange({
      columns: selectedColumns,
      tableName: selectedTable,
      interval: {
        start: Date.now(),
        end: Date.now(),
      },
      searchValues: {
        column: searchColumn,
        value: searchInput,
      },
    });
  };

  const topStart = () => {
    return (
      <>
        <Dropdown
          value={selectedTable}
          onChange={(e) => setSelectedTable(e.value)}
          options={tableNames}
          placeholder="Table"
          style={{ marginRight: 4 }}
        />
        <Dropdown
          value={selectedInterval}
          onChange={(e) => setSelectedInterval(e.value)}
          options={intervals.map((elem) => elem.name)}
          placeholder="Table"
        />
      </>
    );
  };

  const topEnd = () => {
    return showRange ? (
      <>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DateTimePicker
            label="Select Start Date"
            format="DD/MM/YYYY HH:mm"
            maxDateTime={dayjs()}
            minutesStep={1}
            ampm={false}
            onChange={(e: any) =>
              setSelectedDates({ ...selectedDates, start: e["$d"] })
            }
          />
          <DateTimePicker
            label="Select End Date"
            format="DD/MM/YYYY HH:mm"
            maxDateTime={dayjs()}
            minutesStep={0.5}
            ampm={false}
            onChange={(e: any) =>
              setSelectedDates({ ...selectedDates, end: e["$d"] })
            }
          />
        </LocalizationProvider>
      </>
    ) : (
      <></>
    );
  };

  const bottomStart = () => {
    return (
      <div className="p-inputgroup flex-1">
        <Dropdown
          value={searchColumn}
          onChange={(e) => setSearchColumn(e.value)}
          options={searchableColumns}
          placeholder="Columns"
          optionLabel="name"
          optionValue="value"
        />
        <InputText
          placeholder="Enter value"
          value={searchInput}
          onChange={(e) => setSearchInput(e.target.value)}
        />
        <Button label="Search" onClick={onSearch} />
      </div>
    );
  };

  const bottomEnd = () => {
    return (
      <>
        <Button
          icon="pi pi-refresh"
          loading={props.progress}
          className="mr-2"
          style={{ marginRight: "4px" }}
          onClick={() => props.setReload(true)}
        />
        <Button
          icon="pi pi-file-export"
          loading={props.progress}
          className="mr-2"
          style={{ marginRight: "4px" }}
          tooltip="Export csv"
          onClick={() => {
            props.triggerCSV();
          }}
        />
        <MultiSelect
          value={selectedColumns}
          onChange={(e) => {
            setSelectedColumns(e.value);
          }}
          options={columnList}
          optionLabel="name"
          filter
          placeholder="Select Columns"
          maxSelectedLabels={2}
          className="w-full md:w-20rem"
        />
      </>
    );
  };

  return (
    <>
      <Toolbar
        start={topStart}
        end={topEnd}
        style={{ background: "transparent", border: "none" }}
      />
      <Toolbar
        start={bottomStart}
        end={bottomEnd}
        style={{ background: "transparent", border: "none" }}
      />
    </>
  );
};

export default Options;
