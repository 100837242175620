/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { apoloClient } from "../utils/apollo.utils";
import { GET_ALL_PAYMENTS } from "../graphql/overview.query";
import { bifurcatePayments } from "../utils/table.utils";
import moment from "moment";
import { getVoicePayments } from "../utils/api.utils";

const useGetOverview = () => {
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [data, setData] = useState<Array<any>>([]);
  const [loading, setLoading] = useState(false);
  const [reload, setReload] = useState(false);

  const getOverviewData = async () => {
    setLoading(true);
    const diff = moment(endTime).diff(moment(startTime), "days") + 1;

    for (let i = 0; i < diff; i++) {
      const date = moment(endTime)
        .add(-1 * i, "day")
        .format("YYYY-MM-DD");
      const allPayments = await apoloClient.query({
        query: GET_ALL_PAYMENTS(date),
        fetchPolicy: reload ? "network-only" : "cache-first",
      });

      const totalPayments = bifurcatePayments(
        allPayments.data.irctc_payment_status,
        await getVoicePayments(moment(date, "YYYY-MM-DD").format("DD-MM-YYYY")),
        date
      );

      setData((prev) =>
        Object.values(
          [...prev, totalPayments].reduce((acc, elem) => {
            if (!acc[elem.date]) acc[elem.date] = elem;
            return acc;
          }, {})
        )
      );
    }
    setLoading(false);
    setReload(false);
  };

  useEffect(() => {
    setData(() => []);
    getOverviewData();
  }, [startTime, endTime]);

  useEffect(() => {
    if (reload) {
      setData(() => []);
      getOverviewData();
    }
  }, [reload]);

  return {
    data,
    loading,
    startTime,
    endTime,
    setStartTime,
    setEndTime,
    setReload,
  };
};

export { useGetOverview };
