import { gql } from "@apollo/client";
import { ParameterType } from "../types/parameters.types";
import { getFormattedTime } from "../utils/misc.utils";

export const DEFAULT_REFUND_COLUMNS = [
  "PNR",
  "refund_id",
  "rrn",
  "order_id",
  "refund_type",
  "result_msg",
  "result_status",
  "refund_amount",
  "reference_id",
  "accept_refund_timestamp",
  "accept_refund_status",
];

const aggregateQuery = (parameters: ParameterType) => {
  return `irctc_refund_status_aggregate(where: {
    accept_refund_timestamp: {
      _gte: "${getFormattedTime(parameters.filter.interval.start)}",
      _lte: "${getFormattedTime(parameters.filter.interval.end)}"
    }}) {
    aggregate {
      count
    }
  }`;
};

export const GET_REFUNDS = (parameters: ParameterType) => {
  const searchQuery = parameters.filter.searchValues
    ? `${parameters.filter.searchValues.column}: {
    _eq: "${parameters.filter.searchValues.value}"
  }`
    : "";

  return gql`
    query getRefunds {
      ${aggregateQuery(parameters)}
      irctc_refund_status(
        ${searchQuery === "" ? `limit: ${parameters.limit ?? 10}` : ""} 
        offset: ${
          parameters.offset ?? 0
        }, order_by: {accept_refund_timestamp: desc_nulls_last}, where: {
    ${searchQuery}
    ${
      searchQuery === ""
        ? `accept_refund_timestamp: {
      _gte: "${getFormattedTime(parameters.filter.interval.start)}",
      _lte: "${getFormattedTime(parameters.filter.interval.end)}"
    }`
        : ""
    }
    }) {
        ${parameters.columns.filter((elem) => elem !== "PNR").join("\n")}
        booking {
          PNR
        }
      }
    }
  `;
};

export const GET_REFUNDS_CSV = (parameters: ParameterType) => {
  const searchQuery = parameters.filter.searchValues
    ? `${parameters.filter.searchValues.column}: {
    _eq: "${parameters.filter.searchValues.value}"
  }`
    : "";

  return gql`
    query getRefunds {
      irctc_refund_status(
        offset: ${
          parameters.offset ?? 0
        }, order_by: {accept_refund_timestamp: desc_nulls_last}, where: {
    ${searchQuery}
    ${
      searchQuery === ""
        ? `accept_refund_timestamp: {
      _gte: "${getFormattedTime(parameters.filter.interval.start)}",
      _lte: "${getFormattedTime(parameters.filter.interval.end)}"
    }`
        : ""
    }
    }) {
        ${parameters.columns.filter((elem) => elem !== "PNR").join("\n")}
        booking {
          PNR
        }
      }
    }
  `;
};
