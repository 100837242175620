import { gql } from "@apollo/client";

export const GET_ERROR_PAYMENTS = (date: string) => {
  return gql`
    query getErrorPayments {
      irctc_payment_status(
        where: {
          result_status: { _eq: "TXN_SUCCESS" }
          gateway_stage: { _eq: "3" }
          txn_date: {
            _ilike: "%${date}%"
          }
        }
      ) {
        order_id
      }
    }
  `;
};

const GET_ERROR_COUNT = (error: any, orderIds: Array<string>) => {
  return gql`
    query getErrorCount {
      irctc_new_error_aggregate(
        where: {
          data: { _similar: "%(${error.join("|")})%", _nilike: "%DTO%" }
          clienttransactionid: {
            _in: [${orderIds.map((elem) => `"${elem}"`).join(",")}]
          }
        }
      ) {
        aggregate {
          count
        }
      }
    }
  `;
};

export { GET_ERROR_COUNT };
