import { useState } from "react";
import { homeStyle } from "./styles";
import { DEFAULT_BOOKING_COLUMNS } from "../../graphql/booking.query";
import { TableName, getAllColumns } from "../../utils/table.utils";
import TableWrapper from "../common/tableWrapper";
import Options from "../options";
import { getInterval } from "../../utils/misc.utils";
import { ParameterType } from "../../types/parameters.types";
import CSVTool from "../options/csv";

const RawData = () => {
  const [table, setTable] = useState(TableName.BOOKING);
  const [limit, setLimit] = useState(10);
  const [downloadCSV, setDownloadCSV] = useState(false);
  const [reload, setReload] = useState(false);
  const [parameters, setParameters] = useState<ParameterType>({
    columns: DEFAULT_BOOKING_COLUMNS,
    offset: 0,
    filter: { interval: getInterval("hour"), searchValues: null },
  });

  const onRowPerPageChange = (currentRowsPerPage: number) => {
    setLimit(currentRowsPerPage);
  };

  const onPageChange = (page: number) => {
    setParameters({
      columns: parameters.columns,
      offset: page,
      filter: parameters.filter,
    });
  };

  const onParameterChange = (partialParameters: {
    columns: string[];
    tableName: TableName;
    interval: { start: number; end: number };
    searchValues?: { column: string; value: string };
  }) => {
    if (!partialParameters.columns || partialParameters.columns.length === 0)
      return;
    setTable(partialParameters.tableName);
    setParameters({
      columns: partialParameters.columns,
      filter: {
        interval: partialParameters.interval,
        searchValues: partialParameters.searchValues,
      },
      offset: parameters.offset,
    });
  };

  return (
    <div style={homeStyle}>
      {downloadCSV && (
        <CSVTool
          tableName={table}
          parameters={parameters}
          toggle={setDownloadCSV}
        />
      )}
      <Options
        onParameterChange={onParameterChange}
        triggerCSV={() => setDownloadCSV(true)}
        progress={downloadCSV}
        setReload={setReload}
      />
      <TableWrapper
        tableName={table}
        columns={(getAllColumns(table) as any[]).filter((elem) =>
          parameters.columns.includes(elem.value)
        )}
        onPageChange={onPageChange}
        onRowPerPageChange={onRowPerPageChange}
        parameters={{ ...parameters, limit }}
        reload={reload}
        setReload={setReload}
      />
    </div>
  );
};

export default RawData;
