/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { apoloClient } from "../utils/apollo.utils";
import {
  GET_FOOTFALL,
  GET_FOOTFALL_BOOKINGS,
  GET_FOOTFALL_DATE,
  GET_FOOTFALL_PAYMENTS,
} from "../graphql/footfall.query";
import moment from "moment";
import { bifurcateFootfall, getFunnelData } from "../utils/table.utils";
import dayjs from "dayjs";

const useGetFootfall = () => {
  const [date, setDate] = useState("");
  const [data, setData] = useState<Array<any>>([]);
  const [loading, setLoading] = useState(false);
  const [reload, setReload] = useState(false);
  const [minDate, setMinDate] = useState<dayjs.Dayjs>();

  const getData = async () => {
    setLoading(true);
    const _date = moment(date, "YYYY-MM-DD HH:mm:ss.0").format("YYYY-MM-DD");
    const footfall = await apoloClient.query({
      query: GET_FOOTFALL(_date),
      fetchPolicy: reload ? "network-only" : "cache-first",
    });

    const result = bifurcateFootfall(footfall.data.irctc_footfall);

    const payments = await apoloClient.query({
      query: GET_FOOTFALL_PAYMENTS(_date),
    });

    const bookings = await apoloClient.query({
      query: GET_FOOTFALL_BOOKINGS(_date),
    });

    result["ade4a7db-d819-417d-832a-259307fd94c7,14"] =
      payments.data.irctc_payment_status_aggregate.aggregate.count;
    result["ade4a7db-d819-417d-832a-259307fd94c7,15"] =
      bookings.data.irctc_payment_status_aggregate.aggregate.count;

    const min = await apoloClient.query({ query: GET_FOOTFALL_DATE() });

    const entries = Object.entries(result)
      .sort(
        (a, b) => parseInt(a[0].split(",")[1]) - parseInt(b[0].split(",")[1])
      )
      .filter((elem) => parseInt(elem[0].split(",")[1]) > 7);

    setData(getFunnelData(entries));
    setLoading(false);
    setReload(false);
    setMinDate(dayjs(min.data.irctc_footfall[0].date));
  };

  useEffect(() => {
    if (date !== "") {
      getData();
    }
  }, [date]);

  useEffect(() => {
    if (reload) {
      setData(() => []);
      getData();
    }

    return () => {
      setReload(false);
    };
  }, [reload]);

  return {
    data,
    loading,
    reload,
    date,
    minDate,
    setDate,
    setReload,
  };
};

export { useGetFootfall };
