import { RefundResponse } from "../types/refund.types";

const uploadFile = (
  file: any,
  callback?: (row: RefundResponse) => void,
  onComplete?: () => void
) => {
  const formdata = new FormData();
  formdata.append("file", file);

  fetch("/upload", {
    method: "POST",
    body: formdata,
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  }).then(async (response) => {
    const stream = response.body!.getReader();

    while (true) {
      const { value, done } = await stream.read();

      if (done) {
        onComplete && onComplete();
        break;
      }

      const parsed = JSON.parse(new TextDecoder().decode(value));

      callback && callback(parsed);
    }
  });
};

const getVoicePayments = async (date: string) => {
  return await fetch(`/voicePayments?date=${date}`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  }).then(async (response) => {
    return await response.json();
  });
};

const triggerManual = async (body: {
  orderId: string;
  flag: string;
  amount: string;
}) => {
  const response = await fetch("/manualRefund", {
    method: "POST",
    body: JSON.stringify(body),
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });

  return await response.json();
};

export { uploadFile, getVoicePayments, triggerManual };
