/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { useGetTQData } from "../../hooks/tq.hook";
import moment from "moment";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { IntervalColumns } from "../../utils/table.utils";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { PickerDiv, TableGrid } from "./styles";
import { ProgressBar } from "primereact/progressbar";
import { Button } from "primereact/button";

const TQData = () => {
  const { loading, date, arpData, tq10Data, tq11Data, setDate, setReload } =
    useGetTQData();

  useEffect(() => {
    setDate(moment(Date.now()).format("YYYY-MM-DD 00:00:00.0"));
  }, []);

  return (
    <>
      <div style={PickerDiv}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            label="Select Date"
            format="DD/MM/YYYY"
            value={dayjs(date)}
            maxDate={dayjs()}
            onChange={(e: any) =>
              setDate(moment(e["$d"]).format("YYYY-MM-DD 00:00:00.0"))
            }
            sx={{ marginRight: 2, marginTop: 2, marginBottom: 2 }}
          />
        </LocalizationProvider>
        <Button
          icon="pi pi-refresh"
          className="mr-2"
          onClick={() => setReload(true)}
        />
      </div>
      {loading && (
        <ProgressBar mode="indeterminate" style={{ height: "4px" }} />
      )}
      <div style={TableGrid}>
        <DataTable
          id="arp"
          value={arpData}
          tableStyle={{ minWidth: "30rem" }}
          showGridlines
          stripedRows
          title={`ARP ${moment(date).format("DD-MM-YYYY")}`}
        >
          {IntervalColumns.map((elem, index) => (
            <Column key={index} field={elem.value} header={elem.name} />
          ))}
        </DataTable>
        <DataTable
          value={tq10Data}
          tableStyle={{ minWidth: "30rem" }}
          showGridlines
          stripedRows
          title={`TQ 10 AM ${moment(date).format("DD-MM-YYYY")}`}
        >
          {IntervalColumns.map((elem, index) => (
            <Column key={index} field={elem.value} header={elem.name} />
          ))}
        </DataTable>
        <DataTable
          value={tq11Data}
          tableStyle={{ minWidth: "30rem" }}
          showGridlines
          stripedRows
          title={`TQ 11 AM ${moment(date).format("DD-MM-YYYY")}`}
        >
          {IntervalColumns.map((elem, index) => (
            <Column key={index} field={elem.value} header={elem.name} />
          ))}
        </DataTable>
      </div>
    </>
  );
};

export default TQData;
