import { ApolloClient, InMemoryCache } from "@apollo/client";

const headers: Record<string, string> = localStorage.getItem("token")
  ? {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    }
  : {};

export const apoloClient = new ApolloClient({
  uri: process.env.REACT_APP_DB_ENDPOINT,
  cache: new InMemoryCache(),
  headers,
});
