/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { apoloClient } from "../utils/apollo.utils";
import { GET_INTERVAL_BOOKINGS } from "../graphql/booking.query";
import moment from "moment";
import { bifurcateTQData } from "../utils/table.utils";

interface Row {
  time: string;
  count: number;
}

const useGetTQData = () => {
  const [loading, setLoading] = useState(false);
  const [date, setDate] = useState("");
  const [arpData, setARPData] = useState<Array<Row>>([]);
  const [tq10Data, setTQ10Data] = useState<Array<Row>>([]);
  const [tq11Data, setTQ11Data] = useState<Array<Row>>([]);
  const [reload, setReload] = useState(false);

  const getDates = (start: string, end: string) => {
    const startDate = moment(date, "YYYY-MM-DD HH:mm:ss.0").format(
      `MM/DD/YYYY ${start}:00`
    );
    const endDate = moment(date, "YYYY-MM-DD HH:mm:ss.0").format(
      `MM/DD/YYYY ${end}:00`
    );

    return { startDate, endDate };
  };

  const callQuery = async (
    startDate: string,
    endDate: string,
    hour: string
  ) => {
    const bookings = await apoloClient.query({
      query: GET_INTERVAL_BOOKINGS(startDate, endDate),
      fetchPolicy: reload ? "network-only" : "cache-first",
    });

    return bifurcateTQData(bookings.data.irctc_Booking, hour, date);
  };

  const getARPData = async () => {
    const { startDate, endDate } = getDates("08:00", "08:15");
    setARPData(await callQuery(startDate, endDate, "08"));
    setLoading(false);
  };

  const getTQ10Data = async () => {
    const { startDate, endDate } = getDates("10:00", "10:15");
    setTQ10Data(await callQuery(startDate, endDate, "10"));
    setLoading(false);
  };

  const getTQ11Data = async () => {
    const { startDate, endDate } = getDates("11:00", "11:15");
    setTQ11Data(await callQuery(startDate, endDate, "11"));
    setLoading(false);
  };

  useEffect(() => {
    if (date !== "") {
      setLoading(true);
      getARPData();
      getTQ10Data();
      getTQ11Data();
    }
  }, [date]);

  useEffect(() => {
    if (reload) {
      setLoading(true);
      getARPData();
      getTQ10Data();
      getTQ11Data();
    }

    return () => {
      setReload(false);
    };
  }, [reload]);

  return {
    loading,
    date,
    arpData,
    tq10Data,
    tq11Data,
    setDate,
    setReload,
  };
};

export { useGetTQData };
