/* eslint-disable react-hooks/exhaustive-deps */
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { DataTable } from "primereact/datatable";
import { ProgressBar } from "primereact/progressbar";
import { OverviewFilters } from "./styles";
import moment from "moment";
import { Column } from "primereact/column";
import { useEffect } from "react";
import { Button } from "primereact/button";

interface MultiPickerProps {
  columns: Array<any>;
  data: Array<any>;
  startTime: string;
  endTime: string;
  setStartTime: Function;
  setEndTime: Function;
  loading: boolean;
  setReload: Function;
}

const MultiPickerTable = ({
  columns,
  data,
  startTime,
  endTime,
  setStartTime,
  setEndTime,
  loading,
  setReload,
}: MultiPickerProps) => {
  useEffect(() => {
    setStartTime(
      moment(Date.now()).add(-6, "days").format("YYYY-MM-DD 00:00:00.0")
    );
    setEndTime(moment(Date.now()).format("YYYY-MM-DD 23:59:59.0"));
  }, []);

  return (
    <>
      <div style={OverviewFilters}>
        <div>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="Select Start Date"
              format="DD/MM/YYYY"
              value={dayjs(startTime)}
              maxDate={dayjs()}
              minDate={dayjs(endTime).add(-8, "day")}
              onChange={(e: any) =>
                setStartTime(moment(e["$d"]).format("YYYY-MM-DD 00:00:00.0"))
              }
              sx={{ marginRight: 2, marginTop: 2, marginBottom: 2 }}
            />
            <DatePicker
              label="Select End Date"
              format="DD/MM/YYYY"
              value={dayjs(endTime)}
              maxDate={dayjs()}
              onChange={(e: any) =>
                setEndTime(moment(e["$d"]).format("YYYY-MM-DD 00:00:00.0"))
              }
              sx={{ marginTop: 2, marginBottom: 2 }}
            />
          </LocalizationProvider>
        </div>
        <Button
          icon="pi pi-refresh"
          loading={loading}
          className="mr-2"
          onClick={() => setReload(true)}
        />
      </div>
      {loading && (
        <ProgressBar
          mode="indeterminate"
          style={{ height: "4px" }}
        ></ProgressBar>
      )}
      <DataTable
        value={data}
        tableStyle={{ minWidth: "50rem" }}
        showGridlines
        stripedRows
      >
        {columns.map((elem, index) => (
          <Column key={index} field={elem.value} header={elem.name} />
        ))}
      </DataTable>
    </>
  );
};

export default MultiPickerTable;
