export const loginInputWrapper = {
  width: '484px',
  height: '256px',
  position: 'absolute',
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  margin: 'auto'
} as React.CSSProperties;
