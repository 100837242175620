/* eslint-disable react-hooks/exhaustive-deps */
import { useGetErrorsHook } from "../../hooks/errors.hook";
import { ErrorColumns } from "../../utils/table.utils";
import SinglePickerTable from "../common/singlePickerTable";

const ErrorData = () => {
  const { loading, date, data, setDate, setReload } = useGetErrorsHook();

  return (
    <SinglePickerTable
      loading={loading}
      date={date}
      data={data}
      setDate={setDate}
      isHourly={false}
      columns={ErrorColumns}
      setReload={setReload}
    />
  );
};

export default ErrorData;
