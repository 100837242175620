export const TableGrid = {
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "space-between",
  marginBottom: "8px",
  marginTop: "8px",
} as React.CSSProperties;

export const PickerDiv = {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
} as React.CSSProperties;
