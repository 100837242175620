import { gql } from "@apollo/client";
import { ParameterType } from "../types/parameters.types";

export const DEFAULT_BOOKING_COLUMNS = [
  "PNR",
  "journeyDate",
  "bookingDate",
  "userId",
  "clientTransactionId",
  "reservationId",
  "mobileNumber",
  "bookedquota",
  "payment_mode",
  "card_scheme",
];

const aggregateQuery = (parameters: ParameterType) => {
  return `irctc_Booking_aggregate(where: {
    bookingDate: {
      _gte: "${parameters.filter.interval.start}",
      _lte: "${parameters.filter.interval.end}"
    }}) {
    aggregate {
      count
    }
  }`;
};

export const GET_BOOKINGS = (parameters: ParameterType) => {
  const searchQuery = parameters.filter.searchValues
    ? `${parameters.filter.searchValues.column}: {
    _eq: "${parameters.filter.searchValues.value}"
  }`
    : "";

  return gql`
    query getBookings {
      ${aggregateQuery(parameters)}
      irctc_Booking(
        ${searchQuery === "" ? `limit: ${parameters.limit ?? 10}` : ""}
        offset: ${
          parameters.offset ?? 0
        }, order_by: {bookingDate: desc}, where: {
    ${searchQuery}
    ${
      searchQuery === ""
        ? `bookingDate: {
      _gte: "${parameters.filter.interval.start}",
      _lte: "${parameters.filter.interval.end}"
    }`
        : ""
    }
    }) {
        ${parameters.columns
          .filter((elem) => elem !== "payment_mode" && elem !== "card_scheme")
          .join("\n")}
        payments {
          payment_mode
          card_scheme
        }
      }
    }
  `;
};

export const GET_INTERVAL_BOOKINGS = (startDate: string, endDate: string) => {
  return gql`
    query get_tq_bookings {
      irctc_Booking (
        order_by: { dtm: asc }
        where: { dtm: { _gte: "${startDate}", _lte: "${endDate}" } }
      ) {
        dtm
        journeyDate
        bookedquota
      }
    }
  `;
};

export const GET_BOOKINGS_CSV = (parameters: ParameterType) => {
  const searchQuery = parameters.filter.searchValues
    ? `${parameters.filter.searchValues.column}: {
    _eq: "${parameters.filter.searchValues.value}"
  }`
    : "";

  return gql`
    query getBookings {
      irctc_Booking(order_by: {bookingDate: desc}, where: {
    ${searchQuery}
    ${
      searchQuery === ""
        ? `bookingDate: {
      _gte: "${parameters.filter.interval.start}",
      _lte: "${parameters.filter.interval.end}"
    }`
        : ""
    }
    }) {
        ${parameters.columns
          .filter((elem) => elem !== "payment_mode" && elem !== "card_scheme")
          .join("\n")}
        payments {
          payment_mode
          card_scheme
        }
      }
    }
  `;
};
